/**
 * Wrapper over the native console that allows us to easily
 * compile it away by setting a debug flag to false.  
 * Automatically prefixes output (log, warn, error) with
 * a namespace to make troubleshooting easier.
 * @remarks
 * Inspired by https://www.saninnsalas.com/keep-console-log-line-number-in-an-wrapper-function-that-also-add-an-prefix-text/ 
 */
export class ConsoleWrapper{
    private _logprefix: string = '';
    private _debug: boolean = false;

    constructor(debug?: boolean, prefix?: string){
        if(debug){ this._debug = debug;}
        if(prefix) { this._logprefix = `[${prefix}]: `;}
    }

    /**
     * Gets a reference to a wrapped log function
     */
    get log(): Function {
        if(!this._debug){
            return () => {};
        }

        const logPrefix = this._logprefix;
        if(logPrefix){
            return console.log.bind(window.console, logPrefix);
        }else{
            return console.log.bind(window.console);
        }
    }
    /**
     * Gets a reference to a wrapped warn function
     */
    get warn(): Function {
        if(!this._debug){
            return () => {};
        }

        const logPrefix = this._logprefix;
        if(logPrefix){
            return console.warn.bind(window.console, logPrefix);
        }else{
            return console.warn.bind(window.console);
        }
    }
    /**
     * Gets a reference to a wrapped error function
     */
    get error(): Function {
        if(!this._debug){
            return () => {};
        }

        const logPrefix = this._logprefix;
        if(logPrefix){
            return console.error.bind(window.console, logPrefix);
        }else{
            return console.error.bind(window.console);
        }
    }

}