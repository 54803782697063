import * as Cookies from 'js-cookie';
import { ConsoleWrapper } from './consoleWrapper';

export interface rgbColor{
    red: number,
    green: number,
    blue: number
};

/**
 * Common set of methods used by the rest of the SDK.
 */
export class Utilities{
    private namespace: string = 'SYNCACCESS';
    private _console: ConsoleWrapper;

    constructor(
        private debug: boolean, 
        private forceCookiesOverLocalStorage: boolean = true,
        private console?: ConsoleWrapper ){
            const debugOverride: boolean = this.checkDebugOverride();

            debug = debug || debugOverride;
            this._console = console??new ConsoleWrapper(debug,this.namespace);
    }

    /**
     * Helper function to allow us to override debug behavior from the
     * url.
     */
    private checkDebugOverride(){
        let override: boolean = false;
        if(window.location && window.location.search){
            const debugOverride: string = this.getParameterByName('sync-debug',window.location.search);
            override = ( debugOverride.toLowerCase() === 'on' ||
                debugOverride.toLowerCase() === 'true');
        }
        return override;
    }

    /**
     * Get the current date/time
     * @remarks
     * See {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTime| getTime}
     */
    now(){
        return new Date().getTime();
    }

    /**
     * Gets a UTC-formatted string representing an expiration date
     * set 'days' days from current UTC time.
     * @param days Numer of days beyond 'now'
     */
    expirationFromDays(days: number): string{
        let date = new Date();
        date.setTime(this.now() + (days * 24 * 60 * 60 * 1000));
        return date.toUTCString();
    }

    /**
     * Returns the value from localStorage at the given key, or null
     * if no item exists (or if the key is undefined)
     * @param key Item to search for
     * 
     * @remarks 
     * The method will convert stored objects (which are stored as strings)
     * back into actual objects so there is no need to call JSON.parse on
     * the return value from this method
     */
    tryGetLocalStorageItem(key: string): any{
        if(key){
            try{
                return JSON.parse(localStorage.getItem(key));
            }catch(e){
                return null;
            }
        }
        return null;
    }

    /**
     * Stores the given value in localStorage at the given key
     * 
     * @param key The index value at which to store the item
     * @param value The item to be stored
     * 
     * @remarks
     * Objects are automatically serialized to string values before
     * storing them.
     */
    trySetLocalStorageItem(key: string, value: any){
        if(!key) throw new Error('Could not store value for undefined key.');

        try{
            if(typeof value === 'object'){
                localStorage.setItem(key,JSON.stringify(value));
            }
            else{
                localStorage.setItem(key,value);
            }
        }
        catch(e){
            throw new Error(`Could not store value for ${key}`);
        }
    }

    readAndDecodeCookie(key: string): string{
        throw new Error('not implemented!');
    }

    /**
     * Pulls a value from the given query string based on the param name
     * @param name 
     * @param querystring 
     * @remarks
     * This is an updated version of our old getParameterByName function 
     * and leverages the 'modern-browser' URLSearchParams object to 
     * get the job done.  Not supported in IE (https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#Browser_compatibility)
     */
    getParameterByName(name: string, querystring: string){
        const searchParams: URLSearchParams = new URLSearchParams(querystring);

        if(searchParams.has(name)){
            return searchParams.get(name);
        }

        return '';
    }

    /**
     * Returns a wrapper around native console that preserves
     * stack trace and adds a namespacing prefix
     */
    get log(): Function{
        return this._console.log;
    }
    get warn(): Function{
        return this._console.warn;
    }
    get error(): Function{
        return this._console.error;
    }

    /**
     * Set a top-level session cookie
     */
    setSessionCookie(name: string, value: string|object){
        Cookies.set(name,value);
    }

    /**
     * Set an expiring cookie
     */
    setCookie(name: string, value: string | object, expiresInDays: number): void{
        Cookies.set(name,value,{
            expires: expiresInDays
        });
    }

    getCookie(name: string): string|object{
        return Cookies.get(name);
    }

    /**
     * Ensures that a route path has a trailing '/' character
     * @param rawPath The unadorned path value
     */
    normalizePath(rawPath: string): string{
        if(rawPath.endsWith('/')) return rawPath;
         return rawPath + '/';
    }

    /**
     * Copies a simple object properties with simple types 
     * @remarks
     * Does not support Dates, functions, undefined, Infinity, RegExps, 
     * Maps, Sets, Blobs, FileLists, ImageDatas, sparse Arrays, 
     * Typed Arrays or other complex types 
     */
    copySimpleObject(source: Object): Object{
        if(source){
            return JSON.parse(JSON.stringify(source));
        }
    }

    /**
     * Transforms an object's properties to a URL QueryString
     * collection (url-encoding the values)
     */
    queryStringFromObject(source: Object): string{
        let result: string = '';

        if(!source) return result;

        for(const prop in source){
            if(source.hasOwnProperty(prop)){
                const encodedValue: string = encodeURIComponent(source[prop]);
                const param: string = `${prop}=${encodedValue}`;
                result += result ? `&${param}` : `${param}`;
            }
        }

        return result;
    }

    /**
     * Utility function tests whether or not the given object is
     * an empty object (i.e., matches the empty object literal '{}')
     * @param obj 
     */
    objectIsEmpty(obj: Object): boolean{
        if(obj === null) return true;
        
        for(const key in obj){
            if(obj.hasOwnProperty(key)) return false;
        }

        return true;
    }

    /**
     * Utility function to add/edit a query string argument on a given
     * query string. Returns the modified query string
     * @param search - The query string to update
     * @param arg - The parameter being added/updated
     * @param value - The value for the added/updated param
     * @param overwrite - if false and param exists, the current value will be left alone
     * 
     * @remarks 
     * This method will take care of encoding the param values correctly so callers
     * do not need to encode the 'value' argument.
     */
    updateQueryString(search: string, arg: string, value: string, overwrite?: boolean): string{
        const searchParams: URLSearchParams = new URLSearchParams(search);

        const existingValue: string = this.getParameterByName(arg,search);
        
        if(!existingValue){
            searchParams.append(arg,value);
        }else{
            if(overwrite){
                searchParams.set(arg,value);    
            }
        }

        return searchParams.toString();
    }

    /**
     * Utility function to fetch the current page's url
     */
    getCurrentPageUrl(): string{
        return window.location.href;
    }

    /**
     * Utility function to determine if a given url is a syncAccess-targeted
     * url
     * @param url   The URL to test
     * @param baseUrl   The tenant-specific base Url for the current context
     */
    isSyncAccessUrl(url: string, baseUrl: string): boolean{
        const urlToTest: URL = new URL(url);
        const compareToUrl: URL = new URL(baseUrl);

        const result = urlToTest.origin === compareToUrl.origin;
        return result;
    }

    /**
     * Gets the query string (aka search) from a syncAccess-specific
     * Url
     * @param url The SA url from which to grab the search string
     * @remarks
     * Our standard workflow uses AngularJs which wasn't very robust
     * w.r.t. Route handling.  We might add a query string to the end
     * of a standard workflow route that results in something like this:
     * https://subscribe.foo.com/portal/#/login?op=fedAuth
     * The browser treats everything after the "#" (including ?op=fedAuth)
     * as part of the 'hash.'  So, a call to url.search returns empty for
     * a url like this.
     */
    getSearchFromSyncAccessUrl(url: string): string{
        const urlObj = new URL(url);
        const hash = urlObj.hash;

        if(hash && hash.indexOf('?') >= 0){
            const start = urlObj.hash.indexOf('?');
            return hash.substring(start);
        }
        
        return urlObj.search;
    }


    /**
     * Convert a hex color value into RGB
     * inspired by (https://gist.github.com/jed/983661)
     * Accepts hex values as
     *  #aabbcc
     *  #abc
     *  aabbcc
     *  abc
     */
    hexToRgb(hexColor: any): rgbColor{
        const color: number = +('0x' + hexColor.slice(1).replace(hexColor.length < 5 && /./g, '$&$&'));

        if(isNaN(color)) throw new Error(`${hexColor} is not a valid hex color`);

        return {
            red: color >> 16,
            green: color >> 8 & 255,
            blue: color & 255
        };
    }

    /**
     * Determine whether or not the given color is 'dark' (i.e., such that
     * it would be easier to see a white/light color over the top)
     * inspired by ( https://awik.io/determine-color-bright-dark-using-javascript/ and http://alienryderflex.com/hsp.html)
     */
    colorIsDark(hexColor: string): boolean{
        const rgb: rgbColor = this.hexToRgb(hexColor);

        const hsp: number = Math.sqrt(
            0.299 * (rgb.red * rgb.red) +
            0.587 * (rgb.green * rgb.green) +
            0.114 * (rgb.blue * rgb.blue)
        );
        
        return hsp <= 127.5;
    }

    /**
     * Wrapper around the performance api's 'mark' method. Ignores
     * the call if not in debug mode
     * @param markName 
     */
    mark(markName: string): void{
      if(this.debug){
        performance.mark(markName);
      }
    }
    measure(measureName: string, startMark: string, endMark: string): void{
      if(this.debug){
        performance.measure(measureName, startMark, endMark);
      }
    }
    getPerformanceEntries(): any{
      if(this.debug){
        return performance.getEntriesByType('measure');
      }
    }
}