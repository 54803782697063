import { ConsoleWrapper } from "../utilities/consoleWrapper";
import { Utilities } from "../utilities/utilities";

/**
 * Fake Pixel Library used to test the interaction between 
 * SA tags and pixel without having to rely on actual implemenation
 */
(function(win){
    'use strict';
    /**
     * !IMPORTANT! - This name must match the actual name given the library
     * within the RI pixel library code itself, currently that name is 'i360pixel'
     */
    const libraryName: string = 'i360pixel';

    let sdk = win[libraryName] || {};               // alias the global reference
    const console: ConsoleWrapper = new ConsoleWrapper(true,'MOCK-PIXEL-LIB');
    let utilities: Utilities = new Utilities(true,true,console);

    function processQueue(args){
        var params = [].slice.call(args),
        method = params.shift();
    
        if(sdk[method]){
           sdk[method].apply(sdk, params);
         } else {
           utilities.error(`${libraryName} does not have a ${method} function`);
         }
    }    

    sdk.init = function(tenantId: string){
        utilities.log(`init function called with tenant ${tenantId}`);
    };

    sdk.event = function(eventName: string){
        utilities.log(`event function called with eventName ${eventName}`);
    }

    sdk.custom_fields = function(value: string){
        utilities.log(`custom_fields function called with value ${value}`);
    }

    sdk.uid = function(value: string){
        utilities.log(`uid function called with value ${value}`);
    }
    
    /**
     * Process any queued commands (commands issued
     * before the library was loaded)
     */
    for(var i in sdk.q || []){
        utilities.log(`Executing queued command ${i}`,sdk.q[i]);
        processQueue(sdk.q[i]);
    }

    /**
     * Swap the queue with real function now that library is loaded
     * Any calls from client will now get executed directly 
     * rather than queued
     */
    win[libraryName] = function(){
        utilities.log(`Executing live command`,arguments);
        processQueue(arguments);
    };

})(window);
